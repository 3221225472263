<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 2 de 5
            </template>
            <template v-slot:subtitle>
                Seleccionar Carrera
            </template>
            <template v-slot:content>     
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
						<label>Modalidad</label>
						<Dropdown v-model="matricula.modalidad" :options="modalidad" optionLabel="nombre" placeholder="Seleccione..." @change="Modalidad()"/>
					</div>
                </div>                    
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4" v-if="matricula.modalidad">
						<label>Extensión</label>
						<Dropdown v-model="matricula.extension" :options="extensiones.filter(val => val.modalidad == matricula.modalidad.code)" optionLabel="nombre" placeholder="Seleccione..." @change="Validacion1()"/>
                        <small v-show="validationErrors.extension && submitted" style="color: red;">Debe seleccionar la Extensión</small>
					</div>
                </div>          
                <div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-12 p-md-6" v-if="matricula.extension">
						<label>Carrera (Plan de Estudio)</label>
						<Dropdown v-model="matricula.plan" :options="carreras" optionLabel="nombre" placeholder="Seleccione..." @change="Validacion2()"/>
                        <small v-show="validationErrors.plan && submitted" style="color: red;">Debe seleccionar la Carrera a Cursar</small>
					</div>
					<div class="p-field p-col-12 p-md-4" v-if="matricula.extension">
						<label>Turno</label>
						<Dropdown v-model="matricula.turno" :options="turnos" optionLabel="nombre" placeholder="Seleccione..."/>
                        <small v-show="validationErrors.turno && submitted" style="color: red;">Debe seleccionar el Turno</small>
					</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Siguiente" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Preinscripcion','Procesar');

export default {
    data () {
        return {
            submitted: false,
            validationErrors: {},
            modalidad: [],
            extensiones: [],
            carreras: [],
            turnos: [],
            matricula: {
                modalidad: null,
                extension: null,
                plan: null,
                turno: null,
            },    
            compromiso: null,        
        }
    },
	created() {
		this.Mostrar();
	},
    methods: {
		Mostrar() {
            this.modalidad = [];
            this.extensiones = [];
			Consulta.Procesar('Matricula',{
			}).then(response => {
				//this.$store.state.error =response;
                this.modalidad = response.result.modalidad;
                this.extensiones = response.result.extensiones;
                this.compromiso = response.result.compromiso;
				this.$store.commit('Loading');
			});
		},
        Modalidad(){
            this.matricula.extension = null;
        },
		Validacion1() {
            this.carreras = [];
            this.turnos = [];
			Consulta.Procesar('Validacion1',{
                extension: 	this.matricula.extension.code,
                cedula: this.$store.state.matricula
			}).then(response => {
				//this.$store.state.error = response.result;
                this.carreras = response.result.carreras;
				this.$store.commit('Loading');
			});
		},
		Validacion2() {
            this.turnos = [];
			Consulta.Procesar('Validacion2',{
                extension: 	this.matricula.extension.code,
                plan: 	this.matricula.plan.code,
                cedula: this.$store.state.matricula
			}).then(response => {
				//this.$store.state.error = response.result;
                this.turnos = response.result.turnos;
				this.$store.commit('Loading');
			});
		},
        nextPage() {
            this.submitted = true;
            if (this.validateForm()) {
                this.$emit('next-page', {formData: {matricula: this.matricula, compromiso: this.compromiso}, pageIndex: 1});
            }
        },
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },
        validateForm() {
            if (this.matricula.extension==null)
                this.validationErrors['extension'] = true;
            else
                delete this.validationErrors['extension'];
            if (this.matricula.plan==null)
                this.validationErrors['plan'] = true;
            else
                delete this.validationErrors['plan'];
            if (this.matricula.turno==null)
                this.validationErrors['turno'] = true;
            else
                delete this.validationErrors['turno'];

            return !Object.keys(this.validationErrors).length;
        }
    }
}
</script>